import * as React from 'react';
import {CustomIconProps} from "./CustomIconProps";

const QrCodeExtraRounded: React.FC<CustomIconProps> = ({ size = 60, ...props }) => {
    return (
        <svg id="qr-code-extra-rounded"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="90 90 96 96"
             style={{ width: '100%', height: '100%', maxWidth: size, maxHeight: size, borderRadius: "10%" }}
             {...props}
        >
            <defs>
                <clipPath id="qr_code_extra_rounded__a">
                    <path d="M0 0h300v300H0z"/>
                </clipPath>
                <clipPath id="qr_code_extra_rounded__b">
                    <circle cx={118} cy={38} r={4}/>
                    <circle cx={150} cy={38} r={4}/>
                    <path
                        d="M186 42v-8h-4a4 4 0 0 0 0 8M186 34v8h4a4 4 0 0 0 0-8M106 50v-8h-4a4 4 0 0 0 0 8M106 42v8h4a4 4 0 0 0 0-8"/>
                    <circle cx={126} cy={46} r={4}/>
                    <path
                        d="M162 50v-8h-4a4 4 0 0 0 0 8M162 42h8v8h-8zM170 42v8h4a4 4 0 0 0 0-8M194 50h8v-4a4 4 0 0 0-8 0M138 58h8v-4a4 4 0 0 0-8 0M194 50h8v8h-8zM98 66h8v-4a4 4 0 0 0-8 0M146 66v-8h-8a8 8 0 0 0 8 8M146 58v8h8a8 8 0 0 0-8-8M170 66v-8h-4a4 4 0 0 0 0 8M170 58v8h8a8 8 0 0 0-8-8M186 66h8v-8a8 8 0 0 0-8 8M202 58h-8v8a8 8 0 0 0 8-8M106 74v-8h-8a8 8 0 0 0 8 8M106 66h8v8h-8zM114 66v8h8a8 8 0 0 0-8-8"/>
                    <circle cx={134} cy={70} r={4}/>
                    <path
                        d="M154 66h-8v4a4 4 0 0 0 8 0M170 66h8v8h-8zM178 66h8v8h-8zM194 66h-8v8a8 8 0 0 0 8-8M114 74h8v8h-8zM122 74v8h4a4 4 0 0 0 0-8M162 82h8v-8a8 8 0 0 0-8 8M170 74h8v8h-8zM178 74h8v8h-8zM194 82h8v-4a4 4 0 0 0-8 0M98 90h8v-4a4 4 0 0 0-8 0M114 82h8v8h-8zM130 90h8v-4a4 4 0 0 0-8 0M146 90h8v-4a4 4 0 0 0-8 0M170 82h-8v4a4 4 0 0 0 8 0M186 82h-8v4a4 4 0 0 0 8 0M202 82h-8v4a4 4 0 0 0 8 0M98 90h8v8h-8zM106 90h8v8h-8zM122 90h-8v8a8 8 0 0 0 8-8M138 98v-8h-8a8 8 0 0 0 8 8M138 90h8v8h-8zM146 90h8v8h-8zM154 90v8h4a4 4 0 0 0 0-8M42 106h8v-4a4 4 0 0 0-8 0M58 106h8v-8a8 8 0 0 0-8 8M66 98h8v8h-8zM74 98h8v8h-8zM82 98v8h4a4 4 0 0 0 0-8M98 98h8v8h-8zM122 106h8v-4a4 4 0 0 0-8 0"/>
                    <circle cx={182} cy={102} r={4}/>
                    <path
                        d="M202 106h8v-8a8 8 0 0 0-8 8M210 98v8h4a4 4 0 0 0 0-8M226 106h8v-8a8 8 0 0 0-8 8M234 98v8h8a8 8 0 0 0-8-8M250 106h8v-4a4 4 0 0 0-8 0M42 106h8v8h-8zM58 106h8v8h-8zM74 106h8v8h-8zM106 114v-8h-8a8 8 0 0 0 8 8M106 106v8h8a8 8 0 0 0-8-8M122 106h8v8h-8z"/>
                    <circle cx={142} cy={110} r={4}/>
                    <path
                        d="M162 114v-8h-4a4 4 0 0 0 0 8M162 106v8h8a8 8 0 0 0-8-8M202 114v-8h-4a4 4 0 0 0 0 8M202 106h8v8h-8zM226 114v-8h-4a4 4 0 0 0 0 8M226 106h8v8h-8zM234 106h8v8h-8zM258 106h-8v4a4 4 0 0 0 8 0M42 114h8v8h-8zM58 114h8v8h-8zM66 114h8v8h-8zM74 114h8v8h-8zM82 114h8v8h-8zM90 114v8h8a8 8 0 0 0-8-8M114 114h-8v4a4 4 0 0 0 8 0M122 114h8v8h-8zM130 114v8h4a4 4 0 0 0 0-8M162 114h8v8h-8zM210 122v-8h-8a8 8 0 0 0 8 8M210 114v8h4a4 4 0 0 0 0-8M234 122v-8h-8a8 8 0 0 0 8 8M234 114h8v8h-8zM242 114v8h4a4 4 0 0 0 0-8M50 122h-8v4a4 4 0 0 0 8 0M66 130v-8h-8a8 8 0 0 0 8 8M66 122h8v8h-8zM90 122h8v8h-8zM98 122v8h8a8 8 0 0 0-8-8M130 122h-8v4a4 4 0 0 0 8 0M170 130v-8h-8a8 8 0 0 0 8 8M170 122v8h4a4 4 0 0 0 0-8M194 130h8v-4a4 4 0 0 0-8 0"/>
                    <circle cx={262} cy={126} r={4}/>
                    <circle cx={38} cy={134} r={4}/>
                    <path
                        d="M74 138v-8h-8a8 8 0 0 0 8 8M74 130h8v8h-8zM82 130h8v8h-8zM90 130h8v8h-8zM98 130h8v8h-8zM106 130v8h8a8 8 0 0 0-8-8M138 138v-8h-4a4 4 0 0 0 0 8M138 130h8v8h-8zM146 130h8v8h-8zM154 130v8h8a8 8 0 0 0-8-8M194 138v-8h-4a4 4 0 0 0 0 8M194 130h8v8h-8zM202 130h8v8h-8zM210 130v8h8a8 8 0 0 0-8-8"/>
                    <circle cx={254} cy={134} r={4}/>
                    <circle cx={46} cy={142} r={4}/>
                    <path
                        d="M58 146h8v-4a4 4 0 0 0-8 0M114 146v-8h-8a8 8 0 0 0 8 8M114 138h8v8h-8zM122 138v8h8a8 8 0 0 0-8-8M154 146v-8h-8a8 8 0 0 0 8 8M162 138h-8v8a8 8 0 0 0 8-8M170 146h8v-8a8 8 0 0 0-8 8M178 138v8h4a4 4 0 0 0 0-8M210 138h8v8h-8z"/>
                    <circle cx={230} cy={142} r={4}/>
                    <circle cx={246} cy={142} r={4}/>
                    <path d="M258 146h8v-4a4 4 0 0 0-8 0"/>
                    <circle cx={38} cy={150} r={4}/>
                    <path
                        d="M50 154h8v-8a8 8 0 0 0-8 8M58 146h8v8h-8zM66 146h8v8h-8zM74 146h8v8h-8zM82 146h8v8h-8zM90 146h8v8h-8zM98 146v8h4a4 4 0 0 0 0-8M122 146h8v8h-8z"/>
                    <circle cx={142} cy={150} r={4}/>
                    <path
                        d="M170 146h8v8h-8zM186 154h8v-8a8 8 0 0 0-8 8M194 146v8h4a4 4 0 0 0 0-8M210 146h8v8h-8zM218 146v8h4a4 4 0 0 0 0-8M234 154h8v-4a4 4 0 0 0-8 0M266 146h-8v4a4 4 0 0 0 8 0M50 154h8v8h-8zM58 154h8v8h-8zM66 154h8v8h-8zM82 154h-8v8a8 8 0 0 0 8-8"/>
                    <circle cx={110} cy={158} r={4}/>
                    <path d="M122 154h8v8h-8z"/>
                    <circle cx={150} cy={158} r={4}/>
                    <path
                        d="M162 162h8v-8a8 8 0 0 0-8 8M170 154h8v8h-8zM178 154h8v8h-8zM194 154h-8v8a8 8 0 0 0 8-8M218 154h-8v4a4 4 0 0 0 8 0M234 162v-8h-4a4 4 0 0 0 0 8M234 154h8v8h-8zM242 154h8v8h-8zM250 154v8h4a4 4 0 0 0 0-8M42 170h8v-8a8 8 0 0 0-8 8M50 162h8v8h-8zM58 162h8v8h-8z"/>
                    <circle cx={86} cy={166} r={4}/>
                    <path
                        d="M130 170v-8h-8a8 8 0 0 0 8 8M130 162v8h8a8 8 0 0 0-8-8M162 162h8v8h-8zM178 162h-8v8a8 8 0 0 0 8-8M194 170h8v-4a4 4 0 0 0-8 0M234 162h8v8h-8z"/>
                    <circle cx={262} cy={166} r={4}/>
                    <path d="M34 178h8v-8a8 8 0 0 0-8 8M42 170h8v8h-8zM66 170h-8v4a4 4 0 0 0 8 0"/>
                    <circle cx={94} cy={174} r={4}/>
                    <path
                        d="M106 178h8v-8a8 8 0 0 0-8 8M114 170v8h8a8 8 0 0 0-8-8M130 170h8v8h-8zM138 170v8h4a4 4 0 0 0 0-8M170 170h-8v4a4 4 0 0 0 8 0M186 178v-8h-4a4 4 0 0 0 0 8M186 170h8v8h-8zM194 170h8v8h-8zM202 170v8h4a4 4 0 0 0 0-8M218 178h8v-8a8 8 0 0 0-8 8M226 170h8v8h-8zM234 170h8v8h-8zM242 170v8h4a4 4 0 0 0 0-8M34 178h8v8h-8zM42 178h8v8h-8zM50 178v8h8a8 8 0 0 0-8-8M66 186h8v-4a4 4 0 0 0-8 0"/>
                    <circle cx={86} cy={182} r={4}/>
                    <path
                        d="M98 186h8v-8a8 8 0 0 0-8 8M106 178h8v8h-8zM114 178h8v8h-8zM122 178h8v8h-8zM130 178h8v8h-8zM154 186v-8h-4a4 4 0 0 0 0 8M154 178v8h4a4 4 0 0 0 0-8"/>
                    <circle cx={174} cy={182} r={4}/>
                    <path
                        d="M186 178h8v8h-8zM226 178h-8v4a4 4 0 0 0 8 0M258 186h8v-4a4 4 0 0 0-8 0M34 186h8v8h-8zM42 186h8v8h-8zM50 186h8v8h-8zM58 186h8v8h-8zM66 186h8v8h-8zM74 186v8h8a8 8 0 0 0-8-8M90 194h8v-8a8 8 0 0 0-8 8M106 186h-8v8a8 8 0 0 0 8-8M130 186h8v8h-8zM138 186v8h8a8 8 0 0 0-8-8M186 194v-8h-4a4 4 0 0 0 0 8M186 186h8v8h-8zM202 194h8v-8a8 8 0 0 0-8 8M210 186v8h8a8 8 0 0 0-8-8M226 194h8v-4a4 4 0 0 0-8 0M242 194h8v-4a4 4 0 0 0-8 0M266 186h-8v4a4 4 0 0 0 8 0M42 202v-8h-8a8 8 0 0 0 8 8M50 194h-8v8a8 8 0 0 0 8-8M82 202v-8h-8a8 8 0 0 0 8 8M82 194h8v8h-8zM98 194h-8v8a8 8 0 0 0 8-8M122 202v-8h-4a4 4 0 0 0 0 8M122 194h8v8h-8zM130 194h8v8h-8zM138 194h8v8h-8zM146 194h8v8h-8zM154 194h8v8h-8zM162 194h8v8h-8zM170 194v8h4a4 4 0 0 0 0-8M194 202v-8h-8a8 8 0 0 0 8 8M194 194h8v8h-8zM202 194h8v8h-8zM210 194h8v8h-8zM218 194h8v8h-8zM226 194h8v8h-8zM242 194h8v8h-8zM250 194v8h4a4 4 0 0 0 0-8M106 210v-8h-4a4 4 0 0 0 0 8M106 202v8h4a4 4 0 0 0 0-8M122 202h8v8h-8zM138 202h-8v8a8 8 0 0 0 8-8M154 202h8v8h-8zM178 210h8v-4a4 4 0 0 0-8 0M194 202h8v8h-8zM226 202h8v8h-8zM234 202h8v8h-8zM242 202h8v8h-8zM122 210h8v8h-8zM154 210h8v8h-8zM170 218h8v-8a8 8 0 0 0-8 8M178 210h8v8h-8zM186 210h8v8h-8zM194 210h8v8h-8z"/>
                    <circle cx={214} cy={214} r={4}/>
                    <path
                        d="M226 210h8v8h-8zM250 210h-8v4a4 4 0 0 0 8 0M98 226h8v-8a8 8 0 0 0-8 8M106 218h8v8h-8zM114 218h8v8h-8zM122 218h8v8h-8zM146 226h8v-8a8 8 0 0 0-8 8M162 218h-8v8a8 8 0 0 0 8-8M170 218h8v8h-8zM186 218h8v8h-8zM194 218h8v8h-8zM226 218h8v8h-8zM234 218v8h4a4 4 0 0 0 0-8M250 226h8v-4a4 4 0 0 0-8 0M98 226h8v8h-8zM130 234v-8h-8a8 8 0 0 0 8 8M130 226h8v8h-8zM138 226h8v8h-8zM154 226h-8v8a8 8 0 0 0 8-8M162 234h8v-8a8 8 0 0 0-8 8M170 226h8v8h-8zM178 226h8v8h-8zM186 226h8v8h-8zM194 226h8v8h-8zM202 226h8v8h-8zM210 226h8v8h-8zM218 226h8v8h-8zM234 226h-8v8a8 8 0 0 0 8-8M250 226h8v8h-8zM258 226v8h4a4 4 0 0 0 0-8M106 242v-8h-8a8 8 0 0 0 8 8M106 234v8h8a8 8 0 0 0-8-8M146 234h-8v4a4 4 0 0 0 8 0M162 234h8v8h-8zM218 234h8v8h-8zM242 242v-8h-4a4 4 0 0 0 0 8M242 234h8v8h-8zM250 234h8v8h-8zM106 242h8v8h-8zM130 250v-8h-4a4 4 0 0 0 0 8M130 242v8h8a8 8 0 0 0-8-8"/>
                    <circle cx={150} cy={246} r={4}/>
                    <path
                        d="M170 242h-8v4a4 4 0 0 0 8 0M202 250v-8h-4a4 4 0 0 0 0 8M202 242v8h4a4 4 0 0 0 0-8M226 250v-8h-8a8 8 0 0 0 8 8M226 242v8h8a8 8 0 0 0-8-8M258 250v-8h-8a8 8 0 0 0 8 8M258 242v8h8a8 8 0 0 0-8-8M106 258v-8h-4a4 4 0 0 0 0 8M106 250h8v8h-8zM114 250v8h8a8 8 0 0 0-8-8M130 250h8v8h-8zM138 250v8h4a4 4 0 0 0 0-8"/>
                    <circle cx={158} cy={254} r={4}/>
                    <path
                        d="M186 258h8v-4a4 4 0 0 0-8 0M210 258h8v-4a4 4 0 0 0-8 0M226 250h8v8h-8zM234 250h8v8h-8zM242 250v8h4a4 4 0 0 0 0-8M266 250h-8v4a4 4 0 0 0 8 0M114 266v-8h-8a8 8 0 0 0 8 8M114 258h8v8h-8zM122 258h8v8h-8zM138 258h-8v8a8 8 0 0 0 8-8M186 266v-8h-4a4 4 0 0 0 0 8M186 258h8v8h-8zM194 258h8v8h-8zM202 258h8v8h-8zM210 258h8v8h-8zM218 258h8v8h-8zM226 258h8v8h-8zM242 258h-8v8a8 8 0 0 0 8-8"/>
                    <path d="M34 34v56h56V34zm8 8h40v40H42z" clipRule="evenodd"/>
                    <path d="M50 50h24v24H50z"/>
                    <path d="M266 34h-56v56h56zm-8 8v40h-40V42z" clipRule="evenodd"/>
                    <path d="M250 50v24h-24V50z"/>
                    <path d="M34 266h56v-56H34zm8-8v-40h40v40z" clipRule="evenodd"/>
                    <path d="M50 250v-24h24v24z"/>
                </clipPath>
            </defs>
            <path
                fill="#FFF"
                d="M0 0h300v300H0z"
                clipPath="url(#qr_code_extra_rounded__a)"
            />
            <path d="M0 0h300v300H0z" clipPath="url(#qr_code_extra_rounded__b)"/>
        </svg>
    );
}

export default QrCodeExtraRounded;
