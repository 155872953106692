import {DotType} from "qr-code-styling";
import QrCodeSquare from "../icons/QrCodeSquare";
import QrCodeDots from "../icons/QrCodeDots";
import QrCodeRounded from "../icons/QrCodeRounded";
import QrCodeClassy from "../icons/QrCodeClassy";
import QrCodeClassyRounded from "../icons/QrCodeClassyRounded";
import QrCodeExtraRounded from "../icons/QrCodeExtraRounded";
import React, {useEffect, useState} from "react";
import {Box, Thumbnail} from "@wix/design-system";

interface QrCodePatternsToggleProps {
    selected: DotType | undefined
    onClick: (patter: DotType) => void
}

const QrCodePatternsToggle: React.FC<QrCodePatternsToggleProps> = ({ selected, onClick }) => {

  const [pattern, setPattern] = useState<DotType>();

  useEffect(() => {
      setPattern(selected);
  }, [selected]);

  const handleChangePattern = (pattern: DotType) => {
      setPattern(pattern);
      onClick(pattern);
  };

  return (
      <Box direction="horizontal" gap="SP2">
          <Thumbnail size="medium"
                     selected={"square" === pattern}
                     onClick={() => handleChangePattern("square")}
                     image={<QrCodeSquare size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
          <Thumbnail size="medium"
                     selected={"dots" === pattern}
                     onClick={() => handleChangePattern("dots")}
                     image={<QrCodeDots size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
          <Thumbnail size="medium"
                     selected={"rounded" === pattern}
                     onClick={() => handleChangePattern("rounded")}
                     image={<QrCodeRounded size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
          <Thumbnail size="medium"
                     selected={"classy" === pattern}
                     onClick={() => handleChangePattern("classy")}
                     image={<QrCodeClassy size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
          <Thumbnail size="medium"
                     selected={"classy-rounded" === pattern}
                     onClick={() => handleChangePattern("classy-rounded")}
                     image={<QrCodeClassyRounded size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
          <Thumbnail size="medium"
                     selected={"extra-rounded" === pattern}
                     onClick={() => handleChangePattern("extra-rounded")}
                     image={<QrCodeExtraRounded size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
          />
      </Box>
  );
}

export default QrCodePatternsToggle;
