import React from 'react';
import {Button, PopoverMenu} from "@wix/design-system";
import {DownloadImport} from "@wix/wix-ui-icons-common";

interface DropdownButtonProps {
    options: string[];
    onClick: (option: string) => void;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ options, onClick }) => {

  const handleButtonClick = (option: string) => {
      onClick(option);
  };

  return (
      <PopoverMenu appendTo={"parent"}
                   placement={"top"}
                   showArrow={true}
                   triggerElement={({ toggle, open, close }) => (
                       <Button size="medium"
                               onClick={toggle}
                               onMouseEnter={open}
                               onMouseLeave={close}
                               prefixIcon={<DownloadImport />}
                       >
                           Download
                       </Button>
                   )}
      >
          <PopoverMenu.MenuItem text="PNG"
                                onClick={() => handleButtonClick(options[0])}
          />
          <PopoverMenu.MenuItem text="JPEG"
                                onClick={() => handleButtonClick(options[1])}
          />
          <PopoverMenu.MenuItem text="SVG"
                                onClick={() => handleButtonClick(options[2])}
          />
          <PopoverMenu.MenuItem text="WEBP"
                                onClick={() => handleButtonClick(options[3])}
          />
      </PopoverMenu>
  );
};

export default DropdownButton;
