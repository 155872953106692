import {ChartOptions} from 'chart.js';
import React, {useEffect, useState,} from 'react';
import {Bar} from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import {AggregationPeriod, ViewAnalyticsResponse, ViewSource,} from '../models/models';
import {getViewAnalytics} from '../services/analytics-client';
import {useSearchParams} from "react-router-dom";
import moment from 'moment';

interface BarChartProps {
    linkId: number,
    startDate: Date,
    endDate: Date,
    aggregationPeriod: AggregationPeriod,
    datasetVisibility: Map<ViewSource, boolean>
}

const BarChart: React.FC<BarChartProps> = (
    {
        linkId,
        startDate,
        endDate,
        aggregationPeriod,
        datasetVisibility
    }) => {

  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalyticsResponse>(
      { linkId: linkId, views: new Map<string, number>(), qrViews: new Map<string, number>() }
  );

  const [searchParams] = useSearchParams();

  function getXScaleUnit(): false|'day'|'month'|'millisecond'|'second'|'minute'|'hour'|'week'|'quarter'|'year'|undefined {
      switch (aggregationPeriod) {
          case AggregationPeriod.Daily:
              return 'day'
          case AggregationPeriod.Monthly:
              return 'month'
      }
  }

  useEffect(() => {
      getViewAnalytics({
          linkId: linkId,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          aggregationPeriod: aggregationPeriod
      }, searchParams).then(
          res => {
              setViewAnalytics(res.data);
          }
      )
  }, [linkId, startDate, endDate, aggregationPeriod, searchParams]);

  const data = {
      datasets: [
          {
              label: "Link clicks",
              data: viewAnalytics.views,
              maxBarThickness: 150,
              backgroundColor: 'rgba(56, 153, 237, 0.5)',
              hidden: !datasetVisibility.get(ViewSource.Browser)
          },
          {
              label: "QR-code views",
              data: viewAnalytics.qrViews,
              maxBarThickness: 150,
              backgroundColor: 'rgba(159, 209, 246, 0.5)',
              hidden: !datasetVisibility.get(ViewSource.Qr)
          }
      ]
  };

  const options: ChartOptions<'bar'> = {
      plugins: {
          legend: {
              display: false
          },
          tooltip: {
              displayColors: false,
              callbacks: {
                  title: context => {
                      return "";
                  }
              }
          }
      },
      scales: {
          x: {
              type: 'time',
              stacked: true,
              time: {
                  unit: getXScaleUnit()
              },
              grid: {
                  display: false
              }
          },
          y: {
              beginAtZero: true,
              stacked: true,
              grid: {
                  display: true
              },
              border: {
                  display: false
              },
              ticks: {
                  stepSize: 1
              }
          }
      }
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
