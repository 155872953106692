import * as React from 'react';
import {CustomIconProps} from "./CustomIconProps";

const QrCodeDots: React.FC<CustomIconProps> = ({ size = 60, ...props }) => {
    return (
        <svg id="qr-code-dots"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="90 90 96 96"
             style={{ width: '100%', height: '100%', maxWidth: size, maxHeight: size, borderRadius: "10%" }}
             {...props}
        >
            <defs>
                <clipPath id="qr_code_dots__a">
                    <path d="M0 0h300v300H0z"/>
                </clipPath>
                <clipPath id="qr_code_dots__b">
                    <circle cx={118} cy={38} r={4}/>
                    <circle cx={150} cy={38} r={4}/>
                    <circle cx={182} cy={38} r={4}/>
                    <circle cx={190} cy={38} r={4}/>
                    <circle cx={102} cy={46} r={4}/>
                    <circle cx={110} cy={46} r={4}/>
                    <circle cx={126} cy={46} r={4}/>
                    <circle cx={158} cy={46} r={4}/>
                    <circle cx={166} cy={46} r={4}/>
                    <circle cx={174} cy={46} r={4}/>
                    <circle cx={198} cy={46} r={4}/>
                    <circle cx={142} cy={54} r={4}/>
                    <circle cx={198} cy={54} r={4}/>
                    <circle cx={102} cy={62} r={4}/>
                    <circle cx={142} cy={62} r={4}/>
                    <circle cx={150} cy={62} r={4}/>
                    <circle cx={166} cy={62} r={4}/>
                    <circle cx={174} cy={62} r={4}/>
                    <circle cx={190} cy={62} r={4}/>
                    <circle cx={198} cy={62} r={4}/>
                    <circle cx={102} cy={70} r={4}/>
                    <circle cx={110} cy={70} r={4}/>
                    <circle cx={118} cy={70} r={4}/>
                    <circle cx={134} cy={70} r={4}/>
                    <circle cx={150} cy={70} r={4}/>
                    <circle cx={174} cy={70} r={4}/>
                    <circle cx={182} cy={70} r={4}/>
                    <circle cx={190} cy={70} r={4}/>
                    <circle cx={118} cy={78} r={4}/>
                    <circle cx={126} cy={78} r={4}/>
                    <circle cx={166} cy={78} r={4}/>
                    <circle cx={174} cy={78} r={4}/>
                    <circle cx={182} cy={78} r={4}/>
                    <circle cx={198} cy={78} r={4}/>
                    <circle cx={102} cy={86} r={4}/>
                    <circle cx={118} cy={86} r={4}/>
                    <circle cx={134} cy={86} r={4}/>
                    <circle cx={150} cy={86} r={4}/>
                    <circle cx={166} cy={86} r={4}/>
                    <circle cx={182} cy={86} r={4}/>
                    <circle cx={198} cy={86} r={4}/>
                    <circle cx={102} cy={94} r={4}/>
                    <circle cx={110} cy={94} r={4}/>
                    <circle cx={118} cy={94} r={4}/>
                    <circle cx={134} cy={94} r={4}/>
                    <circle cx={142} cy={94} r={4}/>
                    <circle cx={150} cy={94} r={4}/>
                    <circle cx={158} cy={94} r={4}/>
                    <circle cx={46} cy={102} r={4}/>
                    <circle cx={62} cy={102} r={4}/>
                    <circle cx={70} cy={102} r={4}/>
                    <circle cx={78} cy={102} r={4}/>
                    <circle cx={86} cy={102} r={4}/>
                    <circle cx={102} cy={102} r={4}/>
                    <circle cx={126} cy={102} r={4}/>
                    <circle cx={182} cy={102} r={4}/>
                    <circle cx={206} cy={102} r={4}/>
                    <circle cx={214} cy={102} r={4}/>
                    <circle cx={230} cy={102} r={4}/>
                    <circle cx={238} cy={102} r={4}/>
                    <circle cx={254} cy={102} r={4}/>
                    <circle cx={46} cy={110} r={4}/>
                    <circle cx={62} cy={110} r={4}/>
                    <circle cx={78} cy={110} r={4}/>
                    <circle cx={102} cy={110} r={4}/>
                    <circle cx={110} cy={110} r={4}/>
                    <circle cx={126} cy={110} r={4}/>
                    <circle cx={142} cy={110} r={4}/>
                    <circle cx={158} cy={110} r={4}/>
                    <circle cx={166} cy={110} r={4}/>
                    <circle cx={198} cy={110} r={4}/>
                    <circle cx={206} cy={110} r={4}/>
                    <circle cx={222} cy={110} r={4}/>
                    <circle cx={230} cy={110} r={4}/>
                    <circle cx={238} cy={110} r={4}/>
                    <circle cx={254} cy={110} r={4}/>
                    <circle cx={46} cy={118} r={4}/>
                    <circle cx={62} cy={118} r={4}/>
                    <circle cx={70} cy={118} r={4}/>
                    <circle cx={78} cy={118} r={4}/>
                    <circle cx={86} cy={118} r={4}/>
                    <circle cx={94} cy={118} r={4}/>
                    <circle cx={110} cy={118} r={4}/>
                    <circle cx={126} cy={118} r={4}/>
                    <circle cx={134} cy={118} r={4}/>
                    <circle cx={166} cy={118} r={4}/>
                    <circle cx={206} cy={118} r={4}/>
                    <circle cx={214} cy={118} r={4}/>
                    <circle cx={230} cy={118} r={4}/>
                    <circle cx={238} cy={118} r={4}/>
                    <circle cx={246} cy={118} r={4}/>
                    <circle cx={46} cy={126} r={4}/>
                    <circle cx={62} cy={126} r={4}/>
                    <circle cx={70} cy={126} r={4}/>
                    <circle cx={94} cy={126} r={4}/>
                    <circle cx={102} cy={126} r={4}/>
                    <circle cx={126} cy={126} r={4}/>
                    <circle cx={166} cy={126} r={4}/>
                    <circle cx={174} cy={126} r={4}/>
                    <circle cx={198} cy={126} r={4}/>
                    <circle cx={262} cy={126} r={4}/>
                    <circle cx={38} cy={134} r={4}/>
                    <circle cx={70} cy={134} r={4}/>
                    <circle cx={78} cy={134} r={4}/>
                    <circle cx={86} cy={134} r={4}/>
                    <circle cx={94} cy={134} r={4}/>
                    <circle cx={102} cy={134} r={4}/>
                    <circle cx={110} cy={134} r={4}/>
                    <circle cx={134} cy={134} r={4}/>
                    <circle cx={142} cy={134} r={4}/>
                    <circle cx={150} cy={134} r={4}/>
                    <circle cx={158} cy={134} r={4}/>
                    <circle cx={190} cy={134} r={4}/>
                    <circle cx={198} cy={134} r={4}/>
                    <circle cx={206} cy={134} r={4}/>
                    <circle cx={214} cy={134} r={4}/>
                    <circle cx={254} cy={134} r={4}/>
                    <circle cx={46} cy={142} r={4}/>
                    <circle cx={62} cy={142} r={4}/>
                    <circle cx={110} cy={142} r={4}/>
                    <circle cx={118} cy={142} r={4}/>
                    <circle cx={126} cy={142} r={4}/>
                    <circle cx={150} cy={142} r={4}/>
                    <circle cx={158} cy={142} r={4}/>
                    <circle cx={174} cy={142} r={4}/>
                    <circle cx={182} cy={142} r={4}/>
                    <circle cx={214} cy={142} r={4}/>
                    <circle cx={230} cy={142} r={4}/>
                    <circle cx={246} cy={142} r={4}/>
                    <circle cx={262} cy={142} r={4}/>
                    <circle cx={38} cy={150} r={4}/>
                    <circle cx={54} cy={150} r={4}/>
                    <circle cx={62} cy={150} r={4}/>
                    <circle cx={70} cy={150} r={4}/>
                    <circle cx={78} cy={150} r={4}/>
                    <circle cx={86} cy={150} r={4}/>
                    <circle cx={94} cy={150} r={4}/>
                    <circle cx={102} cy={150} r={4}/>
                    <circle cx={126} cy={150} r={4}/>
                    <circle cx={142} cy={150} r={4}/>
                    <circle cx={174} cy={150} r={4}/>
                    <circle cx={190} cy={150} r={4}/>
                    <circle cx={198} cy={150} r={4}/>
                    <circle cx={214} cy={150} r={4}/>
                    <circle cx={222} cy={150} r={4}/>
                    <circle cx={238} cy={150} r={4}/>
                    <circle cx={262} cy={150} r={4}/>
                    <circle cx={54} cy={158} r={4}/>
                    <circle cx={62} cy={158} r={4}/>
                    <circle cx={70} cy={158} r={4}/>
                    <circle cx={78} cy={158} r={4}/>
                    <circle cx={110} cy={158} r={4}/>
                    <circle cx={126} cy={158} r={4}/>
                    <circle cx={150} cy={158} r={4}/>
                    <circle cx={166} cy={158} r={4}/>
                    <circle cx={174} cy={158} r={4}/>
                    <circle cx={182} cy={158} r={4}/>
                    <circle cx={190} cy={158} r={4}/>
                    <circle cx={214} cy={158} r={4}/>
                    <circle cx={230} cy={158} r={4}/>
                    <circle cx={238} cy={158} r={4}/>
                    <circle cx={246} cy={158} r={4}/>
                    <circle cx={254} cy={158} r={4}/>
                    <circle cx={46} cy={166} r={4}/>
                    <circle cx={54} cy={166} r={4}/>
                    <circle cx={62} cy={166} r={4}/>
                    <circle cx={86} cy={166} r={4}/>
                    <circle cx={126} cy={166} r={4}/>
                    <circle cx={134} cy={166} r={4}/>
                    <circle cx={166} cy={166} r={4}/>
                    <circle cx={174} cy={166} r={4}/>
                    <circle cx={198} cy={166} r={4}/>
                    <circle cx={238} cy={166} r={4}/>
                    <circle cx={262} cy={166} r={4}/>
                    <circle cx={38} cy={174} r={4}/>
                    <circle cx={46} cy={174} r={4}/>
                    <circle cx={62} cy={174} r={4}/>
                    <circle cx={94} cy={174} r={4}/>
                    <circle cx={110} cy={174} r={4}/>
                    <circle cx={118} cy={174} r={4}/>
                    <circle cx={134} cy={174} r={4}/>
                    <circle cx={142} cy={174} r={4}/>
                    <circle cx={166} cy={174} r={4}/>
                    <circle cx={182} cy={174} r={4}/>
                    <circle cx={190} cy={174} r={4}/>
                    <circle cx={198} cy={174} r={4}/>
                    <circle cx={206} cy={174} r={4}/>
                    <circle cx={222} cy={174} r={4}/>
                    <circle cx={230} cy={174} r={4}/>
                    <circle cx={238} cy={174} r={4}/>
                    <circle cx={246} cy={174} r={4}/>
                    <circle cx={38} cy={182} r={4}/>
                    <circle cx={46} cy={182} r={4}/>
                    <circle cx={54} cy={182} r={4}/>
                    <circle cx={70} cy={182} r={4}/>
                    <circle cx={86} cy={182} r={4}/>
                    <circle cx={102} cy={182} r={4}/>
                    <circle cx={110} cy={182} r={4}/>
                    <circle cx={118} cy={182} r={4}/>
                    <circle cx={126} cy={182} r={4}/>
                    <circle cx={134} cy={182} r={4}/>
                    <circle cx={150} cy={182} r={4}/>
                    <circle cx={158} cy={182} r={4}/>
                    <circle cx={174} cy={182} r={4}/>
                    <circle cx={190} cy={182} r={4}/>
                    <circle cx={222} cy={182} r={4}/>
                    <circle cx={262} cy={182} r={4}/>
                    <circle cx={38} cy={190} r={4}/>
                    <circle cx={46} cy={190} r={4}/>
                    <circle cx={54} cy={190} r={4}/>
                    <circle cx={62} cy={190} r={4}/>
                    <circle cx={70} cy={190} r={4}/>
                    <circle cx={78} cy={190} r={4}/>
                    <circle cx={94} cy={190} r={4}/>
                    <circle cx={102} cy={190} r={4}/>
                    <circle cx={134} cy={190} r={4}/>
                    <circle cx={142} cy={190} r={4}/>
                    <circle cx={182} cy={190} r={4}/>
                    <circle cx={190} cy={190} r={4}/>
                    <circle cx={206} cy={190} r={4}/>
                    <circle cx={214} cy={190} r={4}/>
                    <circle cx={230} cy={190} r={4}/>
                    <circle cx={246} cy={190} r={4}/>
                    <circle cx={262} cy={190} r={4}/>
                    <circle cx={38} cy={198} r={4}/>
                    <circle cx={46} cy={198} r={4}/>
                    <circle cx={78} cy={198} r={4}/>
                    <circle cx={86} cy={198} r={4}/>
                    <circle cx={94} cy={198} r={4}/>
                    <circle cx={118} cy={198} r={4}/>
                    <circle cx={126} cy={198} r={4}/>
                    <circle cx={134} cy={198} r={4}/>
                    <circle cx={142} cy={198} r={4}/>
                    <circle cx={150} cy={198} r={4}/>
                    <circle cx={158} cy={198} r={4}/>
                    <circle cx={166} cy={198} r={4}/>
                    <circle cx={174} cy={198} r={4}/>
                    <circle cx={190} cy={198} r={4}/>
                    <circle cx={198} cy={198} r={4}/>
                    <circle cx={206} cy={198} r={4}/>
                    <circle cx={214} cy={198} r={4}/>
                    <circle cx={222} cy={198} r={4}/>
                    <circle cx={230} cy={198} r={4}/>
                    <circle cx={246} cy={198} r={4}/>
                    <circle cx={254} cy={198} r={4}/>
                    <circle cx={102} cy={206} r={4}/>
                    <circle cx={110} cy={206} r={4}/>
                    <circle cx={126} cy={206} r={4}/>
                    <circle cx={134} cy={206} r={4}/>
                    <circle cx={158} cy={206} r={4}/>
                    <circle cx={182} cy={206} r={4}/>
                    <circle cx={198} cy={206} r={4}/>
                    <circle cx={230} cy={206} r={4}/>
                    <circle cx={238} cy={206} r={4}/>
                    <circle cx={246} cy={206} r={4}/>
                    <circle cx={126} cy={214} r={4}/>
                    <circle cx={158} cy={214} r={4}/>
                    <circle cx={174} cy={214} r={4}/>
                    <circle cx={182} cy={214} r={4}/>
                    <circle cx={190} cy={214} r={4}/>
                    <circle cx={198} cy={214} r={4}/>
                    <circle cx={214} cy={214} r={4}/>
                    <circle cx={230} cy={214} r={4}/>
                    <circle cx={246} cy={214} r={4}/>
                    <circle cx={102} cy={222} r={4}/>
                    <circle cx={110} cy={222} r={4}/>
                    <circle cx={118} cy={222} r={4}/>
                    <circle cx={126} cy={222} r={4}/>
                    <circle cx={150} cy={222} r={4}/>
                    <circle cx={158} cy={222} r={4}/>
                    <circle cx={174} cy={222} r={4}/>
                    <circle cx={190} cy={222} r={4}/>
                    <circle cx={198} cy={222} r={4}/>
                    <circle cx={230} cy={222} r={4}/>
                    <circle cx={238} cy={222} r={4}/>
                    <circle cx={254} cy={222} r={4}/>
                    <circle cx={102} cy={230} r={4}/>
                    <circle cx={126} cy={230} r={4}/>
                    <circle cx={134} cy={230} r={4}/>
                    <circle cx={142} cy={230} r={4}/>
                    <circle cx={150} cy={230} r={4}/>
                    <circle cx={166} cy={230} r={4}/>
                    <circle cx={174} cy={230} r={4}/>
                    <circle cx={182} cy={230} r={4}/>
                    <circle cx={190} cy={230} r={4}/>
                    <circle cx={198} cy={230} r={4}/>
                    <circle cx={206} cy={230} r={4}/>
                    <circle cx={214} cy={230} r={4}/>
                    <circle cx={222} cy={230} r={4}/>
                    <circle cx={230} cy={230} r={4}/>
                    <circle cx={254} cy={230} r={4}/>
                    <circle cx={262} cy={230} r={4}/>
                    <circle cx={102} cy={238} r={4}/>
                    <circle cx={110} cy={238} r={4}/>
                    <circle cx={142} cy={238} r={4}/>
                    <circle cx={166} cy={238} r={4}/>
                    <circle cx={222} cy={238} r={4}/>
                    <circle cx={238} cy={238} r={4}/>
                    <circle cx={246} cy={238} r={4}/>
                    <circle cx={254} cy={238} r={4}/>
                    <circle cx={110} cy={246} r={4}/>
                    <circle cx={126} cy={246} r={4}/>
                    <circle cx={134} cy={246} r={4}/>
                    <circle cx={150} cy={246} r={4}/>
                    <circle cx={166} cy={246} r={4}/>
                    <circle cx={198} cy={246} r={4}/>
                    <circle cx={206} cy={246} r={4}/>
                    <circle cx={222} cy={246} r={4}/>
                    <circle cx={230} cy={246} r={4}/>
                    <circle cx={254} cy={246} r={4}/>
                    <circle cx={262} cy={246} r={4}/>
                    <circle cx={102} cy={254} r={4}/>
                    <circle cx={110} cy={254} r={4}/>
                    <circle cx={118} cy={254} r={4}/>
                    <circle cx={134} cy={254} r={4}/>
                    <circle cx={142} cy={254} r={4}/>
                    <circle cx={158} cy={254} r={4}/>
                    <circle cx={190} cy={254} r={4}/>
                    <circle cx={214} cy={254} r={4}/>
                    <circle cx={230} cy={254} r={4}/>
                    <circle cx={238} cy={254} r={4}/>
                    <circle cx={246} cy={254} r={4}/>
                    <circle cx={262} cy={254} r={4}/>
                    <circle cx={110} cy={262} r={4}/>
                    <circle cx={118} cy={262} r={4}/>
                    <circle cx={126} cy={262} r={4}/>
                    <circle cx={134} cy={262} r={4}/>
                    <circle cx={182} cy={262} r={4}/>
                    <circle cx={190} cy={262} r={4}/>
                    <circle cx={198} cy={262} r={4}/>
                    <circle cx={206} cy={262} r={4}/>
                    <circle cx={214} cy={262} r={4}/>
                    <circle cx={222} cy={262} r={4}/>
                    <circle cx={230} cy={262} r={4}/>
                    <circle cx={238} cy={262} r={4}/>
                    <path d="M34 34v56h56V34zm8 8h40v40H42z" clipRule="evenodd"/>
                    <path d="M50 50h24v24H50z"/>
                    <path d="M266 34h-56v56h56zm-8 8v40h-40V42z" clipRule="evenodd"/>
                    <path d="M250 50v24h-24V50z"/>
                    <path d="M34 266h56v-56H34zm8-8v-40h40v40z" clipRule="evenodd"/>
                    <path d="M50 250v-24h24v24z"/>
                </clipPath>
            </defs>
            <path
                fill="#FFF"
                d="M0 0h300v300H0z"
                clipPath="url(#qr_code_dots__a)"
            />
            <path d="M0 0h300v300H0z" clipPath="url(#qr_code_dots__b)"/>
        </svg>
    );
};

export default QrCodeDots;
