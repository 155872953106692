import {
    Box,
    Button,
    Card,
    Page,
    Table,
    TableActionCell,
    TableToolbar,
    Text,
    TextButton,
    Tooltip,
    WixDesignSystemProvider
} from '@wix/design-system';
import {
    Activity,
    Add,
    Chat,
    Delete,
    Edit, Globe,
    Star,
    StatusAlertFilled,
    StatusWarningFilled,
    Visible
} from '@wix/wix-ui-icons-common';
import React, {useEffect, useState} from 'react';
import {
    DomainResponse,
    InstanceInfoResponse,
    ShortLinkResponse,
    VendorProductId,
    VerificationStatus,
} from '../models/models';
import {handleCopy} from '../services/clipboard-service';
import {getShortLinks} from '../services/short-link-client';
import AnalyticsModal from './AnalyticsModal';
import CutLink from './CutLink';
import DeleteShortLink from './DeleteShortLink';
import ShortLinkListItem from './ShortLinkListItem';
import {useSearchParams} from "react-router-dom";
import EditShortLink from "./EditShortLink";
import {getInstanceInfo} from "../services/instance-client";
import UpgradeBanner from "./banner/UpgradeBanner";
import QrCodeConfirmationModal from "./QrCodeConfirmationModal";
import QrCodeIcon from "./QrCodeIcon";
import AddDomainModal from "./AddDomainModal";
import DomainManagementModal from "./DomainManagementModal";
import DeleteDomainModal from "./DeleteDomainModal";
import {getDomains} from "../services/domain-client";
import RestartDomainVerificationModal from "./RestartDomainVerificationModal";
import Favicon from "./Favicon";
import StartFreeTrialModal from "./StartFreeTrialModal";
import CustomQrGenerator from "./CustomQrGenerator";
import Fab from './fab/Fab';
import '@wix/design-system/styles.global.css';
import '../styles/main-styles.css';
import '../styles/fab-styles.css';
import UpgradeTooltipMessage from "./UpgradeTooltipMessage";
import HoverButton from "./HoverButton";
import ReviewBanner from "./banner/ReviewBanner";

const TinyLinks: React.FC = () => {

  const [showCutLinkModal, setShowCutLinkModal] = useState(false);
  const [showEditLinkModal, setShowEditLinkModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [showQrCodeConfirmationModal, setShowQrCodeConfirmationModal] = useState(false);
  const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);

  const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);
  const [qrCodeId, setQrCodeId] = useState<number | undefined>(undefined);

  const [showDomainManagementModal, setShowDomainManagementModal] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [showRestartDomainVerificationModal, setShowRestartDomainVerificationModal] = useState(false);
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);

  const [domains, setDomains] = useState<DomainResponse[]>([]);
  const [domainId, setDomainId] = useState<number>(-1);
  const [domainName, setDomainName] = useState<string>("");

  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);
  const [showReviewBanner, setShowReviewBanner] = useState(true);
  const [shortLinksResponse, setShortLinksResponse] = useState<ShortLinkResponse[]>([]);
  const [instanceInfoResponse, setInstanceInfoResponse] = useState<InstanceInfoResponse>(
      {
          appId: '', siteId: '', vendorProductId: VendorProductId.NOT_PURCHASED,
          availableLinks: 0, availableCustomShortUrlCodes: 0, availableQrCodes: 0,
          linksLimit: 0, customShortUrlLimit: 0, qrCodesLimit: 0, isFreeTrialAvailable: false
      }
  );
  const [linkIdForAction, setLinkIdForAction] = useState(1);
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [shortUrlCode, setShortUrlCode] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [createdDate, setCreatedDate] = useState<Date>(new Date());
  const [searchParams] = useSearchParams();

  useEffect(() => {
      refresh();
      }, []);

  const refresh = () => {
      fetchInstanceInfo();
      fetchShortLinks();
  }

  const fetchShortLinks = () => {
      getShortLinks(searchParams)
          .then(res => {
                setShortLinksResponse(res.data)
          })
  };

  const fetchInstanceInfo = () => {
      getInstanceInfo(searchParams)
          .then(res => {
              setInstanceInfoResponse(res.data)
              
              const vendorProductId = res.data.vendorProductId;
              const availableLinks = res.data.availableLinks;
              const isFreeTrialAvailable = res.data.isFreeTrialAvailable;

              if (isFreeTrialAvailable) {
                  setShowFreeTrialModal(true);
              }

              if ((vendorProductId === VendorProductId.NOT_PURCHASED && !isFreeTrialAvailable)
                  || (vendorProductId === VendorProductId.basic && availableLinks / 10 <= 0.2)
                  || (vendorProductId === VendorProductId.business && availableLinks / 100 <= 0.1) ) {
                  setShowUpgradeBanner(true);
              } else {
                  setShowUpgradeBanner(false);
              }
          })

      getDomains(searchParams)
          .then(res => {
              setDomains(res.data)
          })
  }

  const linksListItems = shortLinksResponse.map(shortLinkResponse => ({
      linkId: shortLinkResponse.id,
      favicon: (<Favicon longUrl={shortLinkResponse.longUrl} />),
      shortLinkItem: (<ShortLinkListItem shortLink={shortLinkResponse} />),
      views: (
          <Box>
              <Tooltip inline content="Total Clicks">
                  <Visible color="rgb(56, 153, 237)" />
              </Tooltip>
              <Text weight="thin">{shortLinkResponse.clicks}</Text>
          </Box>
      ),
      longUrl: shortLinkResponse.longUrl,
      shortUrl: `https://${shortLinkResponse.domain.domainName}/${shortLinkResponse.shortUrlCode}`,
      shortUrlCode: shortLinkResponse.shortUrlCode,
      title: shortLinkResponse.title,
      isQrCodeGenerated: shortLinkResponse.qrCodeId !== null,
      qrCodeId: shortLinkResponse.qrCodeId,
      domain: {
          id: shortLinkResponse.domain.id,
          domainName: shortLinkResponse.domain.domainName,
          verificationStatus: shortLinkResponse.domain.verificationStatus
      },
      status: (
          <Box>
              {shortLinkResponse.domain.verificationStatus === VerificationStatus.Deleted && (
                  <Tooltip inline content="Custom domain has been deleted. Link is unavailable.">
                      <StatusWarningFilled color={"#FFB700"} />
                  </Tooltip>
              )}
              {(shortLinkResponse.domain.verificationStatus === VerificationStatus.Failed ||
                shortLinkResponse.domain.verificationStatus === VerificationStatus.InProgress) && (
                  <Tooltip inline content="Custom domain not verified. Please check your DNS settings and rerun domain verification.">
                      <StatusAlertFilled color={"#FE620F"} />
                  </Tooltip>
              )}
          </Box>
      ),
      createdDate: shortLinkResponse.createdDate
  }));

  const isDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED;
  const isCreateLinkDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED || instanceInfoResponse.availableLinks <= 0;
  const isQrCodeGeneratingDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED || instanceInfoResponse.availableQrCodes <= 0;
  const isDomainManagementDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED || instanceInfoResponse.vendorProductId === VendorProductId.basic;

  const primaryAction = (url: string, domainVerificationStatus: VerificationStatus) => ({
      text: "Copy",
      disabled: isDisabled || domainVerificationStatus !== VerificationStatus.Verified,
      onClick: () => handleCopy(url)
  });

  const secondaryActions = (linkId: number,
                            shortUrl: string,
                            shortUrlCode: string,
                            longUrl: string,
                            title: string,
                            isQrCodeGenerated: boolean,
                            qrCodeId: number | undefined,
                            domain: {id: number, domainName: string, verificationStatus: VerificationStatus},
                            createdDate: Date) : (
                                 {
                                    text: string;
                                    icon: React.JSX.Element;
                                    visibility: string;
                                    skin: 'dark' | 'destructive' | undefined;
                                    disabled: boolean;
                                    onClick: () => void;
                                 } |
                                 {
                                     divider: boolean
                                 })[]  => {

      let qrCodeToolTip: string;
      if (domain.verificationStatus === VerificationStatus.Verified) {
          qrCodeToolTip = isQrCodeGenerated ? "QR-code generated" : `${instanceInfoResponse.availableQrCodes} available QR-codes`
      } else if (domain.verificationStatus === VerificationStatus.Deleted) {
          qrCodeToolTip = "Domain was deleted. QR-code is unavailable."
      } else {
          qrCodeToolTip = "Custom domain not verified. QR-code is unavailable."
      }

      return [
          {
              text: qrCodeToolTip,
              icon: <QrCodeIcon isQrCodeGenerated={isQrCodeGenerated}/>,
              visibility: 'always',
              skin: 'dark',
              disabled: isQrCodeGeneratingDisabled || domain.verificationStatus !== VerificationStatus.Verified,
              onClick: () => {
                  if (isQrCodeGenerated) {
                      setShowQrCodeModal(true);
                      setQrCodeId(qrCodeId);
                  } else {
                      setShowQrCodeConfirmationModal(true);
                  }
                  setLinkIdForAction(linkId);
                  setShortUrl(shortUrl);
                  setLinkTitle(title);
              }
          },
          {
              text: "Analytics",
              icon: <Activity />,
              visibility: 'always',
              skin: 'dark',
              disabled: isDisabled,
              onClick: () => {
                  setShowAnalyticsModal(true);
                  setLinkIdForAction(linkId);
                  setCreatedDate(createdDate);
                  setIsQrCodeGenerated(isQrCodeGenerated);
                  setLinkTitle(title);
              }
          },
          {
              text: "Edit",
              icon: <Edit />,
              visibility: 'always',
              skin: 'dark',
              disabled: isDisabled || domain.verificationStatus !== VerificationStatus.Verified,
              onClick: () => {
                  setShowEditLinkModal(true);
                  setLinkIdForAction(linkId);
                  setLinkTitle(title);
                  setLongUrl(longUrl);
                  setShortUrlCode(shortUrlCode);
                  setDomainId(domain.id);
                  setDomainName(domain.domainName);
              }
          },
          {
              divider: true
          },
          {
              text: "Delete",
              icon: <Delete />,
              visibility: 'always',
              skin: 'destructive',
              disabled: isDisabled,
              onClick: () => {
                  setShowDeleteModal(true);
                  setLinkTitle(title);
                  setShortUrl(shortUrl);
                  setLongUrl(longUrl);
              }
          }
      ]
  };

  const NUM_OF_VISIBLE_SECONDARY_ACTION = 2;

  const columns = [
      {
          title: "",
          render: (row: any) => row.favicon,
          width: '32px',
          height: '32px',
      },
      {
          title: "Links",
          render: (row: any) => row.shortLinkItem,
          width: '25%',
          style: { padding: '15px 3px 15px 1px' }
      },
      {
          title: "Views",
          render: (row: any) => row.views,
          width: '4%',
          style: { padding: '15px 3px 15px 2px' }
      },
      {
          title: "Status",
          render: (row: any) => row.status,
          width: '30%',
          style: { padding: '14px 2px 16px 2px' }
      },
      {
          title: "Actions",
          render: (row: any) => (
              <TableActionCell primaryAction={primaryAction(row.shortUrl, row.domain.verificationStatus)}
                               secondaryActions={secondaryActions(row.linkId,
                                                                  row.shortUrl,
                                                                  row.shortUrlCode,
                                                                  row.longUrl,
                                                                  row.title,
                                                                  row.isQrCodeGenerated,
                                                                  row.qrCodeId,
                                                                  row.domain,
                                                                  row.createdDate)}
                               alwaysShowSecondaryActions={true}
                               numOfVisibleSecondaryActions={NUM_OF_VISIBLE_SECONDARY_ACTION}
              />
          )
      }
  ];

  const openAddDomainModal = () => {
      setShowAddDomainModal(true);
      setShowDomainManagementModal(false);
  };

  const openDomainVerificationModal = (domainId: number, domainName: string) => {
      setDomainId(domainId);
      setDomainName(domainName);
      setShowRestartDomainVerificationModal(true);
      setShowDomainManagementModal(false);
  };

  const openDeleteDomainModal = (domainId: number, domainName: string) => {
      setDomainId(domainId);
      setDomainName(domainName);
      setShowDeleteDomainModal(true);
      setShowDomainManagementModal(false);
  };

  const handleReviewButton = () => {
      const url = `https://www.wix.com/app-market/add-review/${instanceInfoResponse.appId}`
      window.open(url, '_blank');
  };

  const handleSupportButton = () => {
      window.location.href = "mailto:support@tiny-links.com";
  }

  return (
    <WixDesignSystemProvider>

        <Page height='100vh'>
            <Page.Header title="TinyLinks"
                         subtitle="Generate and Track Short Links & QR Codes"
                         actionsBar={
                            <Box direction="horizontal" gap="SP2">
                                <Tooltip inline content={`${instanceInfoResponse.availableLinks} available links`}>
                                    <Button size="medium"
                                            prefixIcon={<Add />}
                                            disabled={isCreateLinkDisabled}
                                            onClick={() => setShowCutLinkModal(true)}>
                                        Add Link
                                    </Button>
                                </Tooltip>
                            </Box>
                         }
            />
                
            <Page.Content>
                <Card>
                    <Table data={linksListItems} columns={columns}>
                        <TableToolbar>
                            <TableToolbar.Title>Short Links Collection</TableToolbar.Title>
                        </TableToolbar>

                        <Table.Content titleBarVisible={false} />

                        { linksListItems.length === 0 && (
                            <Table.EmptyState title="Add your first link"
                                              subtitle="Paste long URL and create beautiful short link">
                                <TextButton size="small"
                                            prefixIcon={<Add />}
                                            skin="standard"
                                            priority="secondary"
                                            disabled={isCreateLinkDisabled}
                                            onClick={() => setShowCutLinkModal(true)}>
                                                Add link
                                </TextButton>
                            </Table.EmptyState>
                        )}
                    </Table>
                </Card>
            </Page.Content>

            { showUpgradeBanner && <Page.FixedFooter children={ <UpgradeBanner appId={instanceInfoResponse.appId}
                                                                               siteId={instanceInfoResponse.siteId}
                                                                               vendorProductId={instanceInfoResponse.vendorProductId}/> } /> }
            { (!showUpgradeBanner && showReviewBanner) && <Page.FixedFooter children={ <ReviewBanner appId={instanceInfoResponse.appId}
                                                                                                     onClick={() => setShowReviewBanner(false)} /> }
            /> }
        </Page>

        { showCutLinkModal && <CutLink instanceInfo={instanceInfoResponse}
                                       onSave={(linkId, shortUrl, title, qrCodeId) => {
                                           refresh();

                                           if (qrCodeId) {
                                               setShowQrCodeModal(true);
                                               setQrCodeId(qrCodeId);
                                               setLinkIdForAction(linkId);
                                               setShortUrl(shortUrl);
                                               setLinkTitle(title);
                                           }
                                       }}
                                       onClose={() => setShowCutLinkModal(false)} /> }
        { showEditLinkModal && <EditShortLink linkId={linkIdForAction}
                                              title={linkTitle}
                                              longUrl={longUrl}
                                              shortUrlCode={shortUrlCode}
                                              domainId={domainId}
                                              domainName={domainName}
                                              onSave={refresh}
                                              onClose={() => setShowEditLinkModal(false)} /> }
        { showDeleteModal && <DeleteShortLink shortUrl={shortUrl}
                                              title={linkTitle}
                                              onDelete={refresh}
                                              onClose={() => setShowDeleteModal(false)} /> }
        { showAnalyticsModal && <AnalyticsModal linkId={linkIdForAction}
                                                shortLinkTitle={linkTitle}
                                                vendorProductId={instanceInfoResponse.vendorProductId}
                                                createdDate={createdDate}
                                                isQrCodeGenerated={isQrCodeGenerated}
                                                onClose={() => setShowAnalyticsModal(false)} /> }
        { showQrCodeConfirmationModal && <QrCodeConfirmationModal linkId={linkIdForAction}
                                                                  title={linkTitle}
                                                                  onClose={ () => setShowQrCodeConfirmationModal(false) }
                                                                  onSubmit={ (qrCodeId, linkId) => {
                                                                      setShowQrCodeConfirmationModal(false);
                                                                      setLinkIdForAction(linkId);
                                                                      setQrCodeId(qrCodeId);
                                                                      refresh();
                                                                      setShowQrCodeModal(true);
                                                                  }} /> }
        { showQrCodeModal && <CustomQrGenerator qrCodeId={qrCodeId as number}
                                                shortLinkId={linkIdForAction}
                                                shortUrl={shortUrl}
                                                shortLinkTitle={linkTitle}
                                                onClose={() => setShowQrCodeModal(false)} /> }
        { showDomainManagementModal && <DomainManagementModal openAddDomainModal={() => openAddDomainModal()}
                                                              openRestartDomainVerificationModal={(domainId: number, domainName: string) => openDomainVerificationModal(domainId, domainName)}
                                                              openDeleteModal={(domainId: number, domainName: string) => openDeleteDomainModal(domainId, domainName)}
                                                              onClose={() => setShowDomainManagementModal(false)} /> }
        { showAddDomainModal && <AddDomainModal onSubmit={() => {
                                                    refresh();
                                                    setShowDomainManagementModal(true);
                                                }}
                                                onClose={() => {
                                                    setShowAddDomainModal(false);
                                                    if (domains.length > 0) {
                                                        setShowDomainManagementModal(true);
                                                    }
                                                }} /> }
        { showRestartDomainVerificationModal && <RestartDomainVerificationModal domainId={domainId}
                                                                                onSubmit={refresh}
                                                                                onClose={() => {
                                                                                    setShowRestartDomainVerificationModal(false)
                                                                                    setShowDomainManagementModal(true);
                                                                                }} /> }
        { showDeleteDomainModal && <DeleteDomainModal domainId={domainId}
                                                      domainName={domainName}
                                                      onDelete={refresh}
                                                      onClose={() => {
                                                          setShowDeleteDomainModal(false);
                                                          setShowDomainManagementModal(true);
                                                      }} /> }
        { showFreeTrialModal && <StartFreeTrialModal appId={instanceInfoResponse.appId}
                                                     siteId={instanceInfoResponse.siteId} /> }

        <Fab>
            <Tooltip inline
                     disabled={!isDomainManagementDisabled}
                     content={
                         <Text size="small" light={true}>
                             Your current plan doesn't include short links with custom domain. {<UpgradeTooltipMessage instanceInfo={instanceInfoResponse} content={"Upgrade to unlock"} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                         </Text>
                     }>
                <HoverButton title="Domain Managment"
                             skin={isDomainManagementDisabled ? "premium" : "light"}
                             disabled={isDomainManagementDisabled}
                             onClick={() => {
                                 if (domains.length === 0) {
                                     setShowAddDomainModal(true);
                                 } else {
                                     setShowDomainManagementModal(true);
                                 }
                             }}>
                    <Globe/>
                </HoverButton>
            </Tooltip>

            <HoverButton title="Share Feedback" onClick={handleReviewButton}>
                <Star/>
            </HoverButton>

            <HoverButton title="Support" onClick={handleSupportButton}>
                <Chat/>
            </HoverButton>
        </Fab>

    </WixDesignSystemProvider>
  );
};

export default TinyLinks;
