import {
    CustomModalLayout,
    Modal,
} from '@wix/design-system';
import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {restartDomainVerification} from "../services/domain-client";
import DomainRestartVerificationInstructions from "./DomainRestartVerificationInstruction";

interface RestartDomainVerificationModalProps {
    domainId: number,
    onSubmit: () => void,
    onClose: () => void
}

const RestartDomainVerificationModal: React.FC<RestartDomainVerificationModalProps> = (
    {
        domainId,
        onSubmit,
        onClose
    }) => {

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const handleRestartDomainVerification = (domainId: number) => {
      restartDomainVerification(domainId, searchParams)
          .then(() => onSubmit())
          .finally(() => {
              setShowModal(false);
              onClose();
          });
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout theme={'standard'}
                             title="Rerun verification for custom domain?"
                             primaryButtonText="Submit"
                             primaryButtonOnClick={ () => handleRestartDomainVerification(domainId) }
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             height="54%"
                             width="35%"
                             content={ <DomainRestartVerificationInstructions/> }
          />
      </Modal>
  );
};

export default RestartDomainVerificationModal;
