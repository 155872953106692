import {
    Box,
    CustomModalLayout,
    Dropdown,
    FormField,
    Input,
    Modal,
    Text, Tooltip
} from '@wix/design-system';
import {StatusType} from '@wix/design-system/dist/types/FormField';
import React, {
    ChangeEvent,
    useState,
} from 'react';
import {ShortLinkUpdateRequest} from '../models/models';
import {useSearchParams} from "react-router-dom";
import {updateLinkSchema} from "../validations/UpdateLinkValidation";
import {updateLink} from "../services/short-link-client";
import {ValidationError} from "yup";

interface EditShortLinkProps {
    linkId: number,
    title: string,
    longUrl: string,
    shortUrlCode: string,
    domainId: number,
    domainName: string,
    onClose : () => void,
    onSave : () => void
}

const EditShortLink: React.FC<EditShortLinkProps> = (
    {
        linkId,
        title,
        longUrl,
        shortUrlCode,
        domainId,
        domainName,
        onClose,
        onSave
    }) => {

  const [showModal, setShowModal] = useState(true);
  const [url, setUrl] = useState(longUrl);
  const [inputTitleValue, setInputTitleValue] = useState(title);
  const [titleFieldStatus, setTitleFieldStatus] = useState<StatusType>();
  const [titleStatusMessage, setTitleStatusMessage] = useState("");
  const [urlFieldStatus, setUrlFieldStatus] = useState<StatusType>()
  const [urlStatusMessage, setUrlStatusMessage] = useState("");
  const [updateLinkRequest, setUpdateLinkRequest] = useState<ShortLinkUpdateRequest>({ id: linkId });
  const [searchParams] = useSearchParams();

  const dropdownOptions = [
        {
            id: domainId,
            value: domainName,
            label:domainName,
        }
  ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
      const newValue = e.target.value;

      if (inputName === 'longUrl') {
          setUrl(newValue);
      } else if (inputName === 'title') {
          setInputTitleValue(newValue);
      }

      setUpdateLinkRequest(prevState => ({
          ...prevState,
          [inputName]: newValue.trim()
      }));
  }

  const handleEdit = (id: number) => {
      try {
          updateLinkSchema.validateSync({ title: updateLinkRequest.title, longUrl: updateLinkRequest.longUrl });

          updateLinkRequest.id=id;
          updateLink(updateLinkRequest, searchParams)
              .then(() => onSave())
              .finally(() => {
                  setShowModal(false);
                  onClose();
              })
      } catch (err) {
          if (err instanceof ValidationError) {
              setInputFieldError(err.path, err.message)
          }
      }
  };

 const setInputFieldError = (field: string | undefined, message: string) => {
     if (field === 'longUrl') {
         setUrlFieldStatus("error")
         setUrlStatusMessage(message)
     } else if (field === 'title') {
         setTitleFieldStatus("error")
         setTitleStatusMessage(message)
     }
 };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">

          <CustomModalLayout title="Edit Short Link"
                             primaryButtonText="Edit"
                             primaryButtonOnClick={() => handleEdit(linkId)}
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             overflowY="none"
                             width="50%"
                             content={
                                 <>
                                     <FormField status={urlFieldStatus} statusMessage={urlStatusMessage} required={false}>
                                         <Text>Destination URL</Text>
                                         <Input size="medium"
                                                value={url}
                                                onChange={event => handleInputChange(event, 'longUrl')} />
                                     </FormField>
                                     <p/>
                                     <FormField status={titleFieldStatus} statusMessage={titleStatusMessage} required={false}>
                                         <Text>Title</Text>
                                         <Input size="medium"
                                                value={inputTitleValue}
                                                onChange={event => handleInputChange(event, 'title')} />
                                     </FormField>
                                     <p/>
                                     <Box direction='horizontal'>
                                         <FormField>
                                             <Box direction="vertical">
                                                 <Text>Domain</Text>
                                                 <Tooltip inline content="The domain cannot be changed after a link has been shortened.">
                                                     <Dropdown options={dropdownOptions}
                                                               selectedId={domainId}
                                                               readOnly />
                                                 </Tooltip>
                                             </Box>
                                         </FormField>

                                         <Box paddingTop="SP4" paddingLeft="tiny" paddingRight="tiny">
                                             <h6 style={{
                                                 fontSize: '30px',
                                                 fontWeight: "normal",
                                                 margin: "0px"
                                             }}>/</h6>
                                         </Box>

                                         <FormField required={false}>
                                             <Text>Custom back-half</Text>
                                             <Tooltip inline content="Custom back-half cannot be changed after a link has been shortened.">
                                                 <Input size="medium" value={shortUrlCode} readOnly />
                                             </Tooltip>
                                         </FormField>
                                     </Box>
                                 </>
                             }
          />
      </Modal>
  );
};

export default EditShortLink;
