import React from "react";
import {Box, Button, Card, CustomModalLayout, Heading, Modal, Text} from "@wix/design-system";
import Slideshow from "./Slideshow";

interface StartFreeTrialModalProps {
    appId: string,
    siteId: string
}

const StartFreeTrialModal: React.FC<StartFreeTrialModalProps> = ({appId, siteId}) => {

  const images = [ '/images/free-trial-img-1.png', '/images/free-trial-img-2.png', '/images/free-trial-img-3.png' ];

  const handleButtonClick = (appId: string, siteId: string) => {
    const url = `https://manage.wix.com/app-pricing-plans/${appId}/plan?meta-site-id=${siteId}&origin=in_app_dashboard`
    window.open(url, '_blank');
  }

  return (
      <Modal isOpen
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout overflowY="none"
                             width="35%"
                             content={
                                 <Card>
                                     <Box direction="vertical" align="center" marginTop={'SP3'} marginBottom={'SP1'}>
                                         <Heading>Welcome to TinyLinks!</Heading>

                                         <Box width="100%" height="100%">
                                             <Slideshow images={images} interval={5000}/>
                                         </Box>

                                         <Text weight={"bold"}>Start your 7-day free trial to create short links</Text>

                                         <Box direction='horizontal' align='center' padding='15px'>
                                             <Button priority="primary" skin="premium"
                                                     onClick={() => handleButtonClick(appId, siteId)}>
                                                 Start Free Trial
                                             </Button>
                                         </Box>
                                     </Box>
                                 </Card>
                             }
          />
      </Modal>
  );

}

export default StartFreeTrialModal;
