import * as React from 'react';
import {CustomIconProps} from "./CustomIconProps";

const QrBorderCircleText: React.FC<CustomIconProps> = ({ size = 90, ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 310 310"
             style={{ width: '100%', height: '100%', maxWidth: size, maxHeight: size }}
             {...props}
        >
            <defs>
                <path id="svgviewer-output-8_svg__a" d="M35 255a175 175 0 0 0 240 0"/>
            </defs>
            <rect
                width={275}
                height={275}
                x={18}
                y={18}
                fill="none"
                stroke="#000"
                strokeWidth={35}
                rx={187.5}
            />
            <text
                style={{
                    font: "35px sans-serif",
                    fill: "#fff",
                }}
            >
                <textPath
                    href="#svgviewer-output-8_svg__a"
                    startOffset="50%"
                    textAnchor="middle"
                >
                    {"SCAN ME"}
                </textPath>
            </text>
        </svg>
    );
}

export default QrBorderCircleText;
