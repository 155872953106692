import React from "react";
import {InstanceInfoResponse, VendorProductId} from "../models/models";

interface UpgradeTooltipProps {
    instanceInfo: InstanceInfoResponse,
    content?: string,
    requiredVendorProductIds: VendorProductId[]
}

const UpgradeTooltipMessage: React.FC<UpgradeTooltipProps> = (
    {
        instanceInfo,
        content="Upgrade for more.",
        requiredVendorProductIds
    }) => {

  if (!requiredVendorProductIds.includes(instanceInfo.vendorProductId)) {
      const upgradeUrl = `https://manage.wix.com/app-pricing-plans/${instanceInfo.appId}/plan?meta-site-id=${instanceInfo.siteId}&origin=in_app_dashboard`
      return <a href={upgradeUrl} target="_blank" rel="noopener noreferrer"><u>{content}</u></a>
  }
  return null
};

export default UpgradeTooltipMessage;
