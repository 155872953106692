import * as React from 'react';
import {CustomIconProps} from "./CustomIconProps";

const QrBorderSquareText: React.FC<CustomIconProps> = ({ size = 60, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 310 310"
            style={{ width: '100%', height: '100%', maxWidth: size, maxHeight: size }}
            {...props}
        >
            <rect
                width={250}
                height={250}
                x={31}
                y={31}
                fill="none"
                stroke="#000"
                strokeWidth={35}
                rx={27.5}
            />
            <text
                x={160}
                y={292}
                style={{
                    font: "35px sans-serif",
                    fill: "#fff",
                }}
                textAnchor="middle"
            >SCAN ME</text>
        </svg>
    );
}

export default QrBorderSquareText;
